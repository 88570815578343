import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@material-tailwind/react';
import { ClerkProvider, RedirectToCreateOrganization, RedirectToSignIn, SignedIn, SignedOut, useOrganization, useOrganizationList } from '@clerk/clerk-react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './Components/ErrorFallback.jsx';
import App from './App.jsx';
import { clerk } from './config.js';

import './index.css';

export const Organizationed = () => {
  const { isLoaded: isOrganizationLoaded, organization } = useOrganization();
  const { isLoaded: isOrganizationListLoaded, userMemberships } = useOrganizationList();

  if (!isOrganizationLoaded || !isOrganizationListLoaded) return null;

  if (!organization && userMemberships?.data?.length === 0) {
    return <RedirectToCreateOrganization />;
  }

  if (!organization) throw new Error('Organization not found');

  return <App />;
};

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ThemeProvider>
      <ErrorBoundary fallbackRender={ErrorFallback}>
        <ClerkProvider publishableKey={clerk.publishableKey}>
          <>
            <SignedOut>
              <RedirectToSignIn />
            </SignedOut>

            <SignedIn>
              <Organizationed />
            </SignedIn>
          </>
        </ClerkProvider>
      </ErrorBoundary>
    </ThemeProvider>
  </React.StrictMode>,
);
