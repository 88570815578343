import { gql } from '@apollo/client';

export const WEBHOOKS_GET = gql`
  query webhooks {
    webhooks(first: 1) {
      edges {
        cursor
        node {
          _id
          name
          url
        }
      }
    }
  }
`;
