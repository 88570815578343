import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from '@material-tailwind/react';

const ErrorFallback = ({ error, resetErrorBoundary }) => (
  <Card className="w-[500px] m-auto">
    <CardBody>
      <h2>Something has gone wrong!</h2>
      <pre className="mt-8 inline-block rounded-lg bg-gray-50 px-4 py-2">{error.message}</pre>
      <p className="mt-8 text-lg">
        Please try again later. If the problem persists, contact us at&nbsp;
        <a href="mailto:stefano@aydahq.com?subject=Error on Ayda" className="text-purple-100">
          stefano@aydahq.com
        </a>
      </p>
      <p className="text-lg">
        Meanwhile, you can click the button below to go back to the homepage.
      </p>
      <div className="mt-8">
        <button
          className="btn"
          onClick={() => {
            resetErrorBoundary();
            window.location.reload();
          }}
        >
            Home
        </button>
      </div>
    </CardBody>
  </Card>
);

ErrorFallback.propTypes = {
  resetErrorBoundary: PropTypes.func.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }).isRequired,
};

export default ErrorFallback;
