import { useQuery } from '@apollo/client';
import { Card, CardBody, CardHeader, Typography } from '@material-tailwind/react';
import { Link } from 'react-router-dom';
import Skeleton from '../Components/Skeleton.jsx';
import { COMPANIES_GET } from './Queries.js';
import { Pagination } from '../Components/Pagination.jsx';
import { useQueryParams } from '../Hooks/index.js';

const CompaniesList = () => {
  const [queryParams] = useQueryParams(['after', 'before']);

  const { data: companiesData, loading: companiesLoading } = useQuery(COMPANIES_GET, { variables: { ...queryParams } });

  const traits = companiesData?.companies.edges.reduce((acc, { node: company }) => {
    Object.keys(company.traits ?? {}).forEach((key) => acc.add(key));
    return acc;
  }, new Set());

  return (
    <Card className="h-full w-full">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="flex flex-col justify-between gap-8 md:flex-row md:items-center">
          <div>
            <Typography variant="h5" color="blue-gray">
              Companies
            </Typography>
          </div>
        </div>
      </CardHeader>
      <CardBody className="px-0">
        {companiesLoading ? (
          <Skeleton />
        ) : (
          <>
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  <th className="border-b border-blue-gray-100 bg-blue-gray-50 py-4 px-2">
                    <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                      Name
                    </Typography>
                  </th>
                  {Array.from(traits).map((trait) => (
                    <th key={trait} className="border-b border-blue-gray-100 bg-blue-gray-50 py-4 px-2">
                      <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                        {trait}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {companiesData?.companies.edges.map(({ node }) => (
                  <tr key={node.groupId}>
                    <td className="p-2 border-b border-blue-gray-50 text-sm">
                      <Link to={`/companies/${encodeURIComponent(node.groupId)}`}>{node.name}</Link>
                    </td>
                    {Array.from(traits).map((trait) => (
                      <td key={trait} className="p-2 border-b border-blue-gray-50 text-sm">
                        {node.traits?.[trait] ?? '-'}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>

            {companiesData?.companies ? (
              <div className="mt-4 flex justify-center">
                <Pagination items={companiesData?.companies} isLoading={companiesLoading} className="flex justify-center space-x-4">
                  <Pagination.PrevButton />
                  <Pagination.NextButton />
                </Pagination>
              </div>
            ) : null}
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default CompaniesList;
