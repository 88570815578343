import PropTypes from 'prop-types';
import { Typography } from '@material-tailwind/react';

const NotFound = ({ message }) => (
  <Typography className="text-center mt-8" color="blue-gray">
    {message}
  </Typography>
);

NotFound.propTypes = {
  message: PropTypes.string.isRequired,
};

export default NotFound;
