import { gql } from '@apollo/client';

export const INTERCOM_AUTH_URL_GET = gql`
  query intercomAuthUrl {
    intercomAuthUrl
  }
`;

export const INTERCOM_SYNC = gql`
  mutation intercomSync {
    intercomSync
  }
`;

export const RUN_ANALYSIS = gql`
  mutation runAnalysis {
    runAnalysis
  }
`;
