import { gql } from '@apollo/client';

export const COMPANIES_GET = gql`
  query companies($after: String, $before: String, $first: Int) {
    companies(after: $after, before: $before, first: $first) {
      edges {
        cursor
        node {
          groupId
          name
          traits
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const COMPANY_GET = gql`
  query company($groupId: String!, $eventsAfter: String, $eventsBefore: String) {
    company(groupId: $groupId) {
      groupId
      name
      traits
      events(first: 25, after: $eventsAfter, before: $eventsBefore) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            _id
            type
            event
            sentAt
            properties
          }
        }
      }
      conversations(first: 10) {
        edges {
          cursor
          node {
            sourceId
            parts {
              body
              created_at
              author {
                name
                email
              }
            }
          }
        }
      }
      conversationAnalysis
      score
      reason
      people(first: 20) {
        pageInfo {
          hasNextPage
        }
        edges {
          cursor
          node {
            userId
            name
            email
            lastSeenAt
          }
        }
      }
    }
  }
`;
