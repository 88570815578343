import { useMutation, useQuery } from '@apollo/client';
import { Button, Card, CardBody, CardHeader, Typography } from '@material-tailwind/react';
import { useOrganization } from '@clerk/clerk-react';
import { formatDistanceToNow } from 'date-fns';
import { INTERCOM_AUTH_URL_GET, INTERCOM_SYNC, RUN_ANALYSIS } from './Queries.js';
import Skeleton from '../Components/Skeleton.jsx';

const Settings = () => {
  const { data, loading } = useQuery(INTERCOM_AUTH_URL_GET);
  const [intercomSync, { loading: intercomSyncLoading }] = useMutation(INTERCOM_SYNC);
  const [runAnalysis, { loading: runAnalysisLoading }] = useMutation(RUN_ANALYSIS);
  const { organization, isLoaded } = useOrganization();

  const handleIntercomSync = async () => {
    intercomSync({
      onCompleted: () => {
        organization.reload();
      },
    });
  };

  const handleRunAnalysis = async () => {
    runAnalysis({
      onCompleted: () => {
        organization.reload();
      },
    });
  };

  return (
    <Card className="h-full w-full">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="flex flex-col justify-between gap-8 md:flex-row md:items-center">
          <div>
            <Typography variant="h5" color="blue-gray">
              Settings
            </Typography>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <Typography variant="h5">Intercom</Typography>

        <div className="flex mt-2">
          {loading || !isLoaded ? (
            <Skeleton />
          ) : (
            <>
              <div className="w-[50%]">
                <Typography variant="h6">Status</Typography>
                <Typography variant="small">{organization.publicMetadata.intercomConnected ? '✅ Connected' : '❌ Not connected'}</Typography>
              </div>

              <div className="w-[50%] flex justify-end">
                {organization.publicMetadata.intercomConnected ? (
                  <Button size="sm" color="green" onClick={handleIntercomSync} loading={intercomSyncLoading || organization.publicMetadata.intercomSyncing}>
                    {organization.publicMetadata.intercomSyncing ? 'Syncing...' : 'Sync'}
                  </Button>
                ) : (
                  <a href={data.intercomAuthUrl}>
                    <Button size="sm" color="green">
                      Connect with Intercom
                    </Button>
                  </a>
                )}
              </div>
            </>
          )}
        </div>

        <Typography variant="h5" className="mt-4">
          Analysis
        </Typography>

        <div className="flex mt-2">
          {loading || !isLoaded ? (
            <Skeleton />
          ) : (
            <>
              <div className="w-[50%]">
                <Typography variant="h6">Last run</Typography>
                <Typography variant="small">
                  {organization.publicMetadata.analysisEndedAt
                    ? formatDistanceToNow(organization.publicMetadata.analysisEndedAt, { addSuffix: true })
                    : 'Not yet completed.'}
                </Typography>
              </div>

              <div className="w-[50%] flex justify-end">
                <Button size="sm" color="green" onClick={handleRunAnalysis} loading={runAnalysisLoading}>
                  Run analysis
                </Button>
              </div>
            </>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default Settings;
