import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useSession } from '@clerk/clerk-react';
import { INTERCOM_CALLBACK } from './Queries.js';
import Skeleton from '../Components/Skeleton.jsx';

let firstLoad = true;

const Intercom = () => {
  const query = new URLSearchParams(window.location.search);
  const { session, isLoaded } = useSession();

  const [intercomCallback] = useMutation(INTERCOM_CALLBACK, { variables: { code: query.get('code') } });

  const state = query.get('state');

  useEffect(() => {
    if (!firstLoad || !isLoaded) return;

    if (state !== session.user.id) {
      throw new Error('Invalid state');
    }

    firstLoad = false;

    intercomCallback({
      onCompleted: () => {
        window.location.href = '/settings';
      },
    });
  }, [intercomCallback, isLoaded, session, state]);

  return <Skeleton />;
};

export default Intercom;
