import { useQuery } from '@apollo/client';
import { Card, CardBody, CardHeader, Typography } from '@material-tailwind/react';
import { formatDistanceToNow } from 'date-fns';
import { Link } from 'react-router-dom';
import Skeleton from '../Components/Skeleton.jsx';
import { PEOPLE_GET } from './Queries.js';
import { Pagination } from '../Components/Pagination.jsx';
import { useQueryParams } from '../Hooks/index.js';

const PeopleList = () => {
  const [queryParams] = useQueryParams(['after', 'before', 'groupId']);

  const { data: peopleData, loading: peopleLoading } = useQuery(PEOPLE_GET, { variables: { ...queryParams } });

  const traits = peopleData?.people.edges.reduce((acc, { node: person }) => {
    Object.keys(person.traits ?? []).forEach((key) => acc.add(key));
    return acc;
  }, new Set());

  return (
    <Card className="h-full w-full">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="flex flex-col justify-between gap-8 md:flex-row md:items-center">
          <div>
            <Typography variant="h5" color="blue-gray">
              People
            </Typography>
          </div>
        </div>
      </CardHeader>
      <CardBody className="px-0">
        {peopleLoading ? (
          <Skeleton />
        ) : (
          <>
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  <th className="border-b border-blue-gray-100 bg-blue-gray-50 py-4 px-2">
                    <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                      Id
                    </Typography>
                  </th>
                  <th className="border-b border-blue-gray-100 bg-blue-gray-50 py-4 px-2">
                    <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                      Email
                    </Typography>
                  </th>
                  <th className="border-b border-blue-gray-100 bg-blue-gray-50 py-4 px-2">
                    <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                      Name
                    </Typography>
                  </th>
                  <th className="border-b border-blue-gray-100 bg-blue-gray-50 py-4 px-2">
                    <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                      Company
                    </Typography>
                  </th>
                  <th className="border-b border-blue-gray-100 bg-blue-gray-50 py-4 px-2">
                    <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                      Last seen
                    </Typography>
                  </th>
                  {Array.from(traits).map((trait) => (
                    <th key={trait} className="border-b border-blue-gray-100 bg-blue-gray-50 py-4 px-2">
                      <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                        {trait}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {peopleData?.people.edges.map(({ node }) => (
                  <tr key={node.userId}>
                    <td className="p-2 border-b border-blue-gray-50 text-sm">
                      <Link to={`/people/${encodeURIComponent(node.userId)}`}>{node.userId}</Link>
                    </td>
                    <td className="p-2 border-b border-blue-gray-50 text-sm">{node.email}</td>
                    <td className="p-2 border-b border-blue-gray-50 text-sm">{node.name}</td>
                    <td className="p-2 border-b border-blue-gray-50 text-sm">{node.company?.name ?? '-'}</td>
                    <td className="p-2 border-b border-blue-gray-50 text-sm">
                      {node.lastSeenAt ? formatDistanceToNow(new Date(node.lastSeenAt), { addSuffix: true }) : '-'}
                    </td>
                    {Array.from(traits).map((trait) => (
                      <td key={trait} className="p-2 border-b border-blue-gray-50 text-sm">
                        {node.traits?.[trait] ?? '-'}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>

            {peopleData?.people ? (
              <div className="mt-4 flex justify-center">
                <Pagination items={peopleData?.people} isLoading={peopleLoading} className="flex justify-center space-x-4">
                  <Pagination.PrevButton />
                  <Pagination.NextButton />
                </Pagination>
              </div>
            ) : null}
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default PeopleList;
