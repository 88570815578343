import { Card, CardBody, Input, Spinner, Typography } from '@material-tailwind/react';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { WEBHOOKS_GET } from '../Webhooks/Queries.js';
import { EVENTS_GET } from '../Events/Queries.js';

const Dashboard = () => {
  const { data, loading } = useQuery(WEBHOOKS_GET);
  const { data: eventsData, loading: eventsLoading } = useQuery(EVENTS_GET, { variables: { first: 1 } });
  const navigate = useNavigate();

  if (eventsData?.events.edges.length > 0) {
    navigate('/companies');
  }

  return (
    <div className="flex w-full justify-center">
      <Card className="max-w-lg mt-20 h-auto">
        <CardBody>
          <Typography variant="h3">Welcome to Ayda!</Typography>
          {loading || eventsLoading || !data ? (
            <Spinner className="m-auto mt-4" />
          ) : (
            <>
              <p>Add the following webhook as destination in your Segment account:</p>
              <Input label="Webhook url" value={data.webhooks.edges[0].node.url} readOnly containerProps={{ className: 'mt-4' }} />
            </>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Dashboard;
