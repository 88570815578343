import { gql } from '@apollo/client';

export const EVENTS_GET = gql`
  query events($after: String, $before: String, $first: Int) {
    events(after: $after, before: $before, first: $first, reverse: true) {
      edges {
        cursor
        node {
          _id
          type
          event
          sentAt
          context
          person {
            email
            name
          }
          company {
            name
          }
          properties
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
