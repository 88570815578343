import { useQuery } from '@apollo/client';
import { useParams, Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Typography } from '@material-tailwind/react';
import Markdown from 'react-markdown';
import { formatDistanceToNow } from 'date-fns';
import { COMPANY_GET } from './Queries.js';
import Skeleton from '../Components/Skeleton.jsx';
import NotFound from '../Components/NotFound.jsx';
import { useQueryParams } from '../Hooks/index.js';
import EventsWidget from '../Events/Widget.jsx';

const View = () => {
  const { groupId } = useParams();
  const [{ eventsAfter, eventsBefore }] = useQueryParams(['eventsAfter', 'eventsBefore']);

  const { data, loading } = useQuery(COMPANY_GET, { variables: { groupId, eventsAfter, eventsBefore } });

  if (loading) return <Skeleton />;

  if (!data || !data.company) return <NotFound message="Company not found." />;

  const { name, traits, events, conversations, conversationAnalysis, people } = data.company;

  return (
    <Card className="h-full w-full">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="flex flex-col justify-between gap-8 md:flex-row md:items-center">
          <div>
            <Typography variant="h5" color="blue-gray">
              {name}
            </Typography>
          </div>
        </div>
      </CardHeader>
      <CardBody className="grid grid-cols-12 gap-4 px-4">
        <div className="col-span-3">
          <Card>
            <CardBody>
              <Typography variant="h5">Details</Typography>

              {Number.isFinite(data.company.score) ? (
                <div className="mt-4">
                  <Typography variant="small">Score</Typography>
                  <Typography variant="h6">{data.company.score} / 10</Typography>
                  <Typography variant="small">{data.company.reason}</Typography>
                </div>
              ) : null}

              {Object.entries(traits ?? {}).map(([key, value], index) => (
                <div key={index} className="mt-4">
                  <Typography variant="small">{key}</Typography>
                  <Typography variant="h6">{value}</Typography>
                </div>
              ))}
            </CardBody>
          </Card>

          <Card className="mt-4">
            <CardBody>
              <Typography variant="h5">People</Typography>

              {people?.edges.map(({ node: person }) => (
                <div key={person.userId} className="mt-4">
                  <Typography variant="small">{person.email}</Typography>
                  <Typography variant="h6">
                    <Link to={`/people/${encodeURIComponent(person.userId)}`} className="hover:underline">
                      {person.name}
                    </Link>
                  </Typography>
                  <Typography variant="small">Last seen: {formatDistanceToNow(person.lastSeenAt, { addSuffix: true })}</Typography>
                </div>
              ))}
            </CardBody>
          </Card>
        </div>

        <div className="col-span-9">
          {conversationAnalysis ? (
            <Card className="mt-4 first:mt-0">
              <CardBody>
                <Typography variant="h5" color="blue-gray" className="mb-4">
                  Conversations analysis
                </Typography>
                <Typography variant="small" color="blue-gray">
                  <Markdown>{conversationAnalysis}</Markdown>
                </Typography>
              </CardBody>
            </Card>
          ) : null}

          {conversations?.edges.length > 0 ? (
            <Card className="mt-4 first:mt-0">
              <CardBody>
                <Typography variant="h5" color="blue-gray" className="mb-4">
                  Conversations
                </Typography>
                {conversations?.edges.map(({ node: conversation }) => (
                  <div key={conversation.cursor} className="mt-4">
                    <div className="flex items-start">
                      <div className="flex-grow">
                        {conversation.parts?.map((part, i) => (
                          <Typography variant="small" color="blue-gray" key={i}>
                            <strong>{part.author.name}</strong> - <span dangerouslySetInnerHTML={{ __html: part.body }} />
                          </Typography>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </CardBody>
            </Card>
          ) : null}

          <EventsWidget events={events} loading={loading} />
        </div>
      </CardBody>
    </Card>
  );
};

export default View;
