import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useQueryParams = (paramsNamesArray) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    const updatedQueryParams = Object.fromEntries([...paramsNamesArray.map((paramName) => [paramName, undefined]), ...searchParams]);
    setQueryParams(updatedQueryParams);
  }, [searchParams]);

  const setNewValue = (newValue) => {
    const updatedQueryParams = { ...Object.fromEntries(searchParams), ...newValue };

    const definedSearchParams = Object.entries(updatedQueryParams).reduce((acc, [key, value]) => (value !== undefined ? { ...acc, [key]: value } : acc), {});

    setSearchParams(definedSearchParams);
  };

  return [queryParams, setNewValue];
};
