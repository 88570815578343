/* eslint-disable no-console */

import { useEffect, useMemo } from 'react';
import { List, ListItem, ListItemPrefix } from '@material-tailwind/react';
import { OrganizationSwitcher, useOrganization, UserButton, useSession } from '@clerk/clerk-react';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { createBrowserRouter, NavLink, Outlet, RouterProvider, useLocation } from 'react-router-dom';
import { FaBolt, FaBuilding, FaGear, FaPeopleGroup } from 'react-icons/fa6';
import { apiUrl } from './config.js';
import Dashboard from './Dashboard/Index.jsx';
import EventList from './Events/List.jsx';
import PeopleList from './People/List.jsx';
import PeopleView from './People/View.jsx';
import CompaniesList from './Companies/List.jsx';
import CompanyView from './Companies/View.jsx';
import Settings from './Settings/Index.jsx';
import Intercom from './Auth/Intercom.jsx';

let firstLoad = true;

const App = () => {
  const location = useLocation();
  const { session } = useSession();
  const { organization } = useOrganization();

  const ayda = useMemo(() => {
    const aydaObject = new window.Ayda(import.meta.env.VITE_AYDA_URL);
    return aydaObject;
  }, []);

  useEffect(() => {
    if (firstLoad === false || !session || !organization) return;

    firstLoad = false;

    const doTrack = async () => {
      await ayda.identify(session.user.id, {
        email: session.user.primaryEmailAddress.emailAddress,
        name: session.user.fullName,
      });

      await ayda.group(organization.id, {
        name: organization.name,
      });
    };

    doTrack();
  }, [session, organization, ayda]);

  useEffect(() => {
    ayda.track('page', { path: window.location.pathname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, ayda]);

  const authLink = setContext(async (_, { headers }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${await session.getToken()}`,
    },
  }));

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      });
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const client = new ApolloClient({
    link: errorLink.concat(authLink.concat(createHttpLink({ uri: apiUrl }))),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <div className="flex max-w-screen-2xl mx-auto">
        <div className="w-[280px] h-[100vh] p-4">
          <div className="flex justify-between items-center">
            <OrganizationSwitcher hidePersonal />
            <UserButton />
          </div>

          <List className="p-0 mt-4">
            <NavLink to="/companies">
              <ListItem className="py-2" selected={location.pathname.startsWith('/companies')}>
                <ListItemPrefix>
                  <FaBuilding className="h-4 w-4" />
                </ListItemPrefix>
                Companies
              </ListItem>
            </NavLink>

            <NavLink to="/people">
              <ListItem className="py-2" selected={location.pathname.startsWith('/people')}>
                <ListItemPrefix>
                  <FaPeopleGroup className="h-4 w-4" />
                </ListItemPrefix>
                People
              </ListItem>
            </NavLink>

            <NavLink to="/events">
              <ListItem className="py-2" selected={location.pathname.startsWith('/events')}>
                <ListItemPrefix>
                  <FaBolt className="h-4 w-4" />
                </ListItemPrefix>
                Events
              </ListItem>
            </NavLink>

            <NavLink to="/settings">
              <ListItem className="py-2" selected={location.pathname.startsWith('/settings')}>
                <ListItemPrefix>
                  <FaGear className="h-4 w-4" />
                </ListItemPrefix>
                Settings
              </ListItem>
            </NavLink>
          </List>
        </div>

        <div className="w-full">
          <Outlet />
        </div>
      </div>
    </ApolloProvider>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <Dashboard />,
      },
      {
        path: '/auth/intercom',
        element: <Intercom />,
      },
      {
        path: '/events',
        element: <EventList />,
      },
      {
        path: '/people',
        element: <PeopleList />,
      },
      {
        path: '/people/:userId',
        element: <PeopleView />,
      },
      {
        path: '/companies',
        element: <CompaniesList />,
      },
      {
        path: '/companies/:groupId',
        element: <CompanyView />,
      },
      {
        path: '/settings',
        element: <Settings />,
      },
    ],
  },
]);

const AppWithRouter = () => <RouterProvider router={router} />;

export default AppWithRouter;
