import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import classnames from 'classnames';

export const PrevNextButtons = ({ pageInfo, edges, prefix = '' }) => {
  const location = useLocation();

  const previousPageLink = React.useMemo(() => {
    if (edges.length === 0) return '#';

    const search = new URLSearchParams(location.search);
    search.set(prefix ? `${prefix}Before` : 'before', edges[0].cursor);
    search.delete(prefix ? `${prefix}After` : 'after');

    return `${location.pathname}?${search.toString()}`;
  }, [location.search, location.pathname, prefix, edges]);

  const nextPageLink = React.useMemo(() => {
    if (edges.length === 0) return '#';

    const search = new URLSearchParams(location.search);
    search.delete(prefix ? `${prefix}Before` : 'before');
    search.set(prefix ? `${prefix}After` : 'after', edges.slice(-1)[0].cursor);

    return `${location.pathname}?${search.toString()}`;
  }, [location.search, location.pathname, prefix, edges]);

  if (edges.length === 0) return null;

  return (
    <div className="mt-6 flex justify-center">
      <Link
        aria-label="previous"
        className={`hover:bg-purple-110 rounded-full p-2 text-xl ${
          !pageInfo.hasPreviousPage ? 'text-gray-75 pointer-events-none bg-gray-50' : 'bg-purple-100 text-white'
        }`}
        to={previousPageLink}
      >
        <FiChevronLeft />
      </Link>
      <Link
        aria-label="next"
        className={`hover:bg-purple-110 ml-4 rounded-full p-2 text-xl ${
          !pageInfo.hasNextPage ? 'text-gray-75 pointer-events-none bg-gray-50' : 'bg-purple-100 text-white'
        }`}
        to={nextPageLink}
      >
        <FiChevronRight />
      </Link>
    </div>
  );
};

PrevNextButtons.propTypes = {
  pageInfo: PropTypes.shape({
    hasPreviousPage: PropTypes.bool.isRequired,
    hasNextPage: PropTypes.bool.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  edges: PropTypes.array.isRequired,
  prefix: PropTypes.string,
};

const PaginationContext = React.createContext();

const usePaginationContext = () => {
  const context = React.useContext(PaginationContext);
  if (!context) {
    throw new Error('Pagination compound components cannot be rendered outside the Pagination component');
  }
  return context;
};

export const Pagination = ({ children, prefix = '', items = null, isLoading = false, size = 36, ...restProps }) => {
  const value = React.useMemo(
    () => ({
      prefix,
      items,
      isLoading,
      size,
    }),
    [items, isLoading, prefix, size],
  );

  if (items?.edges.length === 0) return null;

  return (
    <PaginationContext.Provider value={value}>
      <div {...restProps}>{children}</div>
    </PaginationContext.Provider>
  );
};

Pagination.propTypes = {
  children: PropTypes.node.isRequired,
  prefix: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.object,
  isLoading: PropTypes.bool,
  size: PropTypes.number,
};

const PaginationButton = ({ isNext, onClick, ...restProps }) => {
  const { prefix, items, isLoading, size } = usePaginationContext();
  const [searchParams] = useSearchParams();

  const isDisabled = (isNext && !items?.pageInfo?.hasNextPage) || (!isNext && !items?.pageInfo?.hasPreviousPage);

  const ChevronIcon = isNext ? FiChevronRight : FiChevronLeft;

  const cursor = isNext ? items?.edges?.slice(-1)[0]?.cursor : items?.edges[0]?.cursor;

  const pageLink = React.useMemo(() => {
    if (!onClick) {
      const beforeKey = prefix ? `${prefix}Before` : 'before';
      const afterKey = prefix ? `${prefix}After` : 'after';

      searchParams.delete(beforeKey);
      searchParams.delete(afterKey);
      searchParams.set(isNext ? afterKey : beforeKey, cursor);
    }

    return `?${searchParams}`;
  }, [cursor, searchParams, isNext, onClick, prefix]);

  const handleClick = (e) => {
    if (!onClick) return;

    e.preventDefault();
    onClick(cursor);
  };

  return (
    <Link
      to={pageLink}
      onClick={(e) => handleClick(e)}
      aria-label={isNext ? 'next' : 'previous'}
      style={{ width: `${size}px`, height: `${size}px` }}
      className={classnames(
        'hover:bg-purple-110 flex items-center justify-center rounded-full',
        isDisabled ? 'text-gray-75 pointer-events-none bg-gray-50' : 'bg-purple-100 text-white',
        isLoading && 'pointer-events-none',
      )}
      {...restProps}
    >
      <ChevronIcon size={size * 0.6} strokeWidth={80 / size} className={classnames(isNext ? 'ml-0.5' : 'mr-0.5')} />
    </Link>
  );
};

PaginationButton.propTypes = {
  isNext: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

const PaginationPrevButton = (props) => <PaginationButton {...props} isNext={false} />;
const PaginationNextButton = (props) => <PaginationButton {...props} isNext />;

PaginationPrevButton.propTypes = { onClick: PropTypes.func };
PaginationNextButton.propTypes = { onClick: PropTypes.func };

Pagination.PrevButton = PaginationPrevButton;
Pagination.NextButton = PaginationNextButton;
