import PropTypes from 'prop-types';
import { Card, CardBody, Typography } from '@material-tailwind/react';
import { Pagination } from '../Components/Pagination.jsx';

const Widget = ({ events, loading }) => (
  <Card className="mt-4 first:mt-0">
    <CardBody>
      <Typography variant="h5" color="blue-gray" className="mb-4">
        Events
      </Typography>
      {events?.edges.map(({ node: event }) => (
        <div key={event._id} className="mt-2">
          <div className="flex items-start">
            <div className="flex-grow">
              <Typography variant="small" color="blue-gray">
                {event.type} - {new Date(event.sentAt).toLocaleString()}
                {event.event ? ` - ${event.event}` : null}
                {event.event === 'page' ? ` - ${event.properties?.path}` : null}
              </Typography>
            </div>
          </div>
        </div>
      ))}

      <Pagination items={events} isLoading={loading} className="mt-4 flex justify-center space-x-4" prefix="events" size={20}>
        <Pagination.PrevButton />
        <Pagination.NextButton />
      </Pagination>
    </CardBody>
  </Card>
);

Widget.propTypes = {
  events: PropTypes.shape({
    edges: PropTypes.arrayOf(PropTypes.object),
  }),
  loading: PropTypes.bool,
};

export default Widget;
