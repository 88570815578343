import { useQuery } from '@apollo/client';
import { Card, CardBody, CardHeader, Typography } from '@material-tailwind/react';
import Skeleton from '../Components/Skeleton.jsx';
import { EVENTS_GET } from './Queries.js';
import { Pagination } from '../Components/Pagination.jsx';
import { useQueryParams } from '../Hooks/index.js';

const EventList = () => {
  const [queryParams] = useQueryParams(['after', 'before']);

  const { data: eventsData, loading: eventsLoading } = useQuery(EVENTS_GET, { variables: { ...queryParams } });

  const properties = eventsData?.events.edges.reduce((acc, { node: event }) => {
    Object.keys(event.properties ?? {}).forEach((key) => acc.add(key));
    return acc;
  }, new Set());

  return (
    <Card className="h-full w-full">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="flex flex-col justify-between gap-8 md:flex-row md:items-center">
          <div>
            <Typography variant="h5" color="blue-gray">
              Recent Events
            </Typography>
          </div>
        </div>
      </CardHeader>
      <CardBody className="px-0">
        {eventsLoading ? (
          <Skeleton />
        ) : (
          <>
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  <th className="border-b border-blue-gray-100 bg-blue-gray-50 py-4 px-2">
                    <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                      Time
                    </Typography>
                  </th>
                  <th className="border-b border-blue-gray-100 bg-blue-gray-50 py-4 px-2">
                    <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                      Type
                    </Typography>
                  </th>
                  <th className="border-b border-blue-gray-100 bg-blue-gray-50 py-4 px-2">
                    <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                      Event
                    </Typography>
                  </th>
                  <th className="border-b border-blue-gray-100 bg-blue-gray-50 py-4 px-2">
                    <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                      Company
                    </Typography>
                  </th>
                  <th className="border-b border-blue-gray-100 bg-blue-gray-50 py-4 px-2">
                    <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                      Person
                    </Typography>
                  </th>
                  {Array.from(properties).map((property) => (
                    <th key={property} className="border-b border-blue-gray-100 bg-blue-gray-50 py-4 px-2">
                      <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                        {property}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {eventsData?.events.edges.map(({ node }) => (
                  <tr key={node._id}>
                    <td className="p-2 border-b border-blue-gray-50 text-sm">{new Date(node.sentAt).toLocaleString()}</td>
                    <td className="p-2 border-b border-blue-gray-50 text-sm">{node.type}</td>
                    <td className="p-2 border-b border-blue-gray-50 text-sm max-w-[300px] truncate">{node.event}</td>
                    <td className="p-2 border-b border-blue-gray-50 text-sm">{node.company?.name ?? '-'}</td>
                    <td className="p-2 border-b border-blue-gray-50 text-sm">{node.person?.name ?? 'Anonymous'}</td>

                    {Array.from(properties).map((property) => (
                      <td key={property} className="p-2 border-b border-blue-gray-50 text-sm">
                        {node.properties?.[property] ?? '-'}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            {eventsData?.events ? (
              <div className="mt-4 flex justify-center">
                <Pagination items={eventsData?.events} isLoading={eventsLoading} className="flex justify-center space-x-4">
                  <Pagination.PrevButton />
                  <Pagination.NextButton />
                </Pagination>
              </div>
            ) : null}
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default EventList;
