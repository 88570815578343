import { useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, Typography } from '@material-tailwind/react';
import Markdown from 'react-markdown';
import { PERSON_GET } from './Queries.js';
import Skeleton from '../Components/Skeleton.jsx';
import NotFound from '../Components/NotFound.jsx';
import EventsWidget from '../Events/Widget.jsx';
import { useQueryParams } from '../Hooks/index.js';

const View = () => {
  const { userId } = useParams();
  const [{ eventsAfter, eventsBefore }] = useQueryParams(['eventsAfter', 'eventsBefore']);

  const { data, loading } = useQuery(PERSON_GET, { variables: { userId, eventsAfter, eventsBefore } });

  if (loading) return <Skeleton />;

  if (!data || !data.person) return <NotFound message="Person not found." />;

  const { name, company, traits, events, conversations, conversationAnalysis } = data.person;

  return (
    <Card className="h-full w-full">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="flex flex-col justify-between gap-8 md:flex-row md:items-center">
          <div>
            <Typography variant="h5" color="blue-gray">
              {name}
            </Typography>
          </div>
        </div>
      </CardHeader>
      <CardBody className="grid grid-cols-12 gap-4 px-4">
        <div className="col-span-3">
          <Card>
            <CardBody>
              <Typography variant="h5">Details</Typography>

              {company ? (
                <div className="mt-4">
                  <Typography variant="small">Company</Typography>
                  <Typography variant="h6">
                    <Link to={`/companies/${encodeURIComponent(company.groupId)}`} className="hover:underline">
                      {company.name ?? 'Unknown company'}
                    </Link>
                  </Typography>
                </div>
              ) : null}

              <div className="mt-4">
                <Typography variant="small">Email</Typography>
                <Typography variant="h6">{data.person.email}</Typography>
              </div>

              {Object.entries(traits ?? {}).map(([key, value], index) => (
                <div key={index} className="mt-4">
                  <Typography variant="small">{key}</Typography>
                  <Typography variant="h6">{value}</Typography>
                </div>
              ))}
            </CardBody>
          </Card>
        </div>

        <div className="col-span-9">
          <Card>
            <CardBody>
              <Typography variant="h5" color="blue-gray" className="mb-4">
                Conversations analysis
              </Typography>
              <Typography variant="small" color="blue-gray">
                {conversationAnalysis ? <Markdown>{conversationAnalysis}</Markdown> : 'No analysis available.'}
              </Typography>
            </CardBody>
          </Card>

          <Card className="mt-4">
            <CardBody>
              <Typography variant="h5" color="blue-gray" className="mb-4">
                Conversations
              </Typography>
              {conversations.edges.map(({ node: conversation }) => (
                <div key={conversation.cursor} className="mt-4">
                  <div className="flex items-start">
                    <div className="flex-grow">
                      {conversation.parts?.map((part, i) => (
                        <Typography variant="small" color="blue-gray" key={i}>
                          <strong>{part.author.name}</strong> - <span dangerouslySetInnerHTML={{ __html: part.body }} />
                        </Typography>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </CardBody>
          </Card>

          <EventsWidget events={events} loading={loading} />
        </div>
      </CardBody>
    </Card>
  );
};

export default View;
