import { gql } from '@apollo/client';

export const PEOPLE_GET = gql`
  query people($after: String, $before: String, $first: Int, $groupId: String) {
    people(after: $after, before: $before, first: $first, groupId: $groupId) {
      edges {
        cursor
        node {
          userId
          email
          name
          lastSeenAt
          company {
            name
          }
          traits
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const PERSON_GET = gql`
  query person($userId: String!, $eventsAfter: String, $eventsBefore: String) {
    person(userId: $userId) {
      userId
      email
      name
      lastSeenAt
      company {
        groupId
        name
      }
      traits
      events(first: 25, after: $eventsAfter, before: $eventsBefore) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            _id
            type
            event
            sentAt
            properties
          }
        }
      }
      conversations(first: 10) {
        edges {
          cursor
          node {
            sourceId
            parts {
              body
              created_at
              author {
                name
                email
              }
            }
          }
        }
      }
    }
  }
`;
